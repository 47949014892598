<!-- new reports screen -->
<!-- new reports screen -->
<ng-container *ngIf="reportsTwoMainScreen">
  <main class="reports-new-main-screen background__wrapper">
    <section
      class="new-reports-tiles-part background__wrapper d-flex align-items-center gap---30"
    >
      <div class="new-reports-card col-md-4">
        <h1 class="fw600 fs16 figma_blue_color">Job Reports: Jobs/Vacancies</h1>
        <div
          class="brandwise-line mt-3 d-flex align-items-center justify-content-between pointer"
          (click)="toggleBrandWiseActiveJobsScreen()"
        >
          <h2 class="fw500 fs18">School Wise</h2>
          <img src="./assets/img/forward-arrow.svg" alt="next" />
        </div>
        <div class="my-line"></div>
        <div
          class="brandwise-line d-flex align-items-center justify-content-between pointer"
          (click)="toggleJobsWiseActiveJobsScreen()"
        >
          <h2 class="fw500 fs18">Country Wise</h2>
          <img src="./assets/img/forward-arrow.svg" alt="next" />
        </div>
      </div>
      <div class="new-reports-card w-100">
        <h1 class="fw600 fs16 figma_blue_color">Applicant Report:</h1>
        <div class="d-flex align-items-baseline">
          <div class="col-md-6 pl-0">
            <div
              class="brandwise-line mt-3 d-flex align-items-center justify-content-between pointer"
              (click)="toggleApplicantsCountScreen()"
            >
              <h2 class="fw500 fs18">Stage Wise</h2>
              <img src="./assets/img/forward-arrow.svg" alt="next" />
            </div>
            <div class="my-line"></div>
            <div
              class="brandwise-line d-flex align-items-center justify-content-between pointer"
              (click)="toggleApplicantsCountryScreen()"
            >
              <h2 class="fw500 fs18">Country Wise</h2>
              <img src="./assets/img/forward-arrow.svg" alt="next" />
            </div>
          </div>

          <div
            class="brandwise-line col-md-6 pointer"
            (click)="toggleResumeBankScreen()"
          >
            <div class="d-flex align-items-center justify-content-between">
              <h2 class="fw500 fs18">Applicant Pool</h2>
              <img src="./assets/img/forward-arrow.svg" alt="next" />
            </div>
            <div class="my-line"></div>
          </div>
        </div>
      </div>
    </section>
    <ng-container *ngIf="reportsTable">
      <div class="reports-table-main new-reports-card mt-3">
        <div class="d-lg-flex align-items-center justify-content-between mb-3 flex-wrap">
          <div class="d-flex align-items-center mb-md-2">
            <h1 class="fs22 fw600">Tracker Report</h1>
            <button
              class="figma_blue_button tracker-button ml-4"
              (click)="toggleGeneratereport()"
            >
              {{ !generateReport ? "Generate Report" : "Hide Report" }}
            </button>
          </div>
          <div class="d-flex align-items-center" *ngIf="generateReport">
            <div class="relative figma_search_part d-flex justify-content-end">
              <input
              id="reports-new-search"
              class="ml-auto"
              placeholder="Search"
              [(ngModel)]="reportsSearchh"
              (ngModelChange)="showCross()"
              (keyup)="GetJobsReportsearchItem($event.target.value)"
            />
              <img
                id="search-input--icons"
                class="absolute figma__search__Icon"
                src="./assets/img/search-icon.svg"
                alt="search"
              />
              <img
                id="search-input--icons-2"
                class="job-searchh-new position-absolute pointer"
                src="./assets/img/figma-close-iconBlue-1.svg"
                (click)="removeValue()"
                alt="cross"
                *ngIf="blueCrossMark"
              />
            </div>
            <button
              type="button"
              class="new-download-report-button ml-2 d-flex"
              (click)="getExcelReports()"
            >
              <img
                class="mr-1 img-fluid"
                src="./assets/img/document-download.svg"
                alt="download"
              />
              Download
            </button>
            <!-- <button id="download-report-button"
              type="button"
              class="btn figma_blue_button ml-3"
              (click)="getExcelReports()"
            >
              Download Report
            </button> -->
          </div>

        </div>
        <div class="loader" *ngIf="reportsLoader"></div>
        <ng-container *ngIf="generateReport">
          <div id="sectionId" class="table_scrollbar" style="overflow: scroll; height:80vh;">
            <table
              class="reports-table-design"
              *ngIf="
                (getReport ).length > 0;
                else noResults
              "
            >
              <thead class="div-table-head">
                <tr class="d-flex w-100 justify-content-between">
                  <th class="cell">Sl.No</th>
                  <th class="cell">
                    Schools
                    <!-- (click)="sortSchool('schools')"
              <svg *ngIf="!dateArrow" width="20" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
              </svg>

              <svg *ngIf="dateArrow" width="20" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
              </svg> -->
            </th>
                  <th class="cell">Country</th>
                  <th class="cell">Campus</th>
                  <th class="cell">Job ID</th>
                  <th class="cell">Job Title</th>
                  <th class="cell text-center">Vacancies</th>
                  <th class="cell">
                    Category <br />
                    (Ac/Non-Ac)
                  </th>
                  <!-- <div class="cell">Job Created By</div> -->
                  <th class="cell">
                    New <br />
                    /Replacement
                  </th>
                  <th class="cell d-flex">Job Status</th>
                  <!-- (Active/Close) -->
                  <!-- <div class="cell">Created On</div>
              <div class="cell text-center">Applicants</div>
              <div class="cell text-center">New</div>
              <div class="cell text-center">Screening</div>
              <div class="cell text-center">Interview</div>
              <div class="cell text-center">Offer</div>
              <div class="cell text-center">Post-Offer</div> -->
                  <!-- (click)="sortColumn('status')" -->
                  <!-- <svg *ngIf="!dateArrow" width="20" height="20" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
              </svg>

              <svg *ngIf="dateArrow" width="20" height="20" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
              </svg> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class="div-table-body-main"
                  *ngFor="
                    let x of getReport ;
                    let i = index
                  "
                >
                  <div class="div-table-body  d-flex align-items-center justify-content-between">
                    <td class="cell">{{ i + 1 }}</td>
                    <td class="cell">{{ x.school }}</td>
                    <td class="cell">{{ x.country }}</td>
                    <td class="cell">
                      <ng-container *ngFor="let x of commaSeperated(x.campus)">
                        <span> {{ x }} </span>
                      </ng-container>
                      <!-- {{ x.campus }} -->
                    </td>
                    <td class="cell">{{ x.jobId }}</td>
                    <td class="cell text-overflow">{{ x.jobTitle }}</td>
                    <td class="cell text-center">{{ x.vacancies }}</td>
                    <td class="cell">{{ x.category }}</td>
                    <!-- <div class="cell">{{ x.createdBy }}</div> -->
                    <td class="cell">{{ x.type || "--" }}</td>
                    <td class="cell text-center">
                      <div
                        class="reports-status"
                        [ngClass]="{
                          activeReports: x.status === 'Active',
                          closedReports: x.status === 'Close'
                        }"
                      >
                        <!-- [ngClass]="{activeReports: reportsStatus}"
                      [ngClass]="reportsStatus ? 'activeReports' : 'closedReports'" -->
                        {{ x.status }}
                      </div>
                    </td>
                    <!-- <div class="cell">
                {{ x.createdOn | date : "MMM dd,YYYY" }}
              </div>
              <div class="cell text-center">{{ x.noOfApplicants || "0" }}</div>
              <div class="cell text-center">{{ x.newStage || "0" }}</div>
              <div class="cell text-center">{{ x.screeningStage || "0" }}</div>
              <div class="cell text-center">{{ x.interviewStage || "0" }}</div>
              <div class="cell text-center">{{ x.offeredStage || "0" }}</div>
              <div class="cell text-center">{{ x.postOfferStage || "0" }}</div> -->
                  </div>
                  <div
                    class="tracker-report-mid-div d-flex align-items-center justify-content-between"
                  >
                    <div class="left-div">
                      <!-- Applicants: 0 | New: 0 | Screening: 0 | Interview: 0 | Offer: 0 | Post Offer: 0 -->
                      <div class="d-flex align-items-center left-div-items">
                        <p class="relative pr-1">Applicants : {{ x.noOfApplicants }} <span class="report-red" pTooltip="Overall Rejected Count"> [{{x.noOfApplicantsRejectedCount}}] </span>
                          <!-- <span class="tooltip ttoooltipp absolute" style="left: -13px; top: 5px">
                            <svg width="10" height="10" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16"></svg>
                            <span class="tooltiptext my-tooltip---text">
                            Overall Rejected Count
                            </span>
                          </span>  -->
                        </p>
                        <p class=" px-2 relative">New : {{ x.newStage }} </p>
                        <p class="relative pl-2">Screening : {{ x.screeningStage }} <span class="report-red" pTooltip="Rejected Count"> [{{x.screeningRejectedCount}}] </span>
                          <!-- <span class="tooltip ttoooltipp absolute" style="left: -13px; top: 5px">
                            <svg width="10" height="10" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16"></svg>
                            <span class="tooltiptext my-tooltip---text">
                             Rejected Count
                            </span>
                          </span> -->
                        </p>
                        <p class="relative pl-2">Interview : {{ x.interviewStage }} <span class="report-red"  pTooltip="Rejected Count"> [{{x.interviewRejectedCount}}] </span>
                          <!-- <span class="tooltip ttoooltipp absolute" style="left: -13px; top: 5px">
                            <svg width="10" height="10" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16"></svg>
                            <span class="tooltiptext my-tooltip---text">
                             Rejected Count
                            </span>
                          </span> -->
                        </p>
                        <p class="relative pl-2">Offer : {{ x.offeredStage }} <span class="report-red" pTooltip="Rejected Count"> [{{x.offeredRejectedCount}}] </span>
                          <!-- <span class="tooltip ttoooltipp absolute" style="left: -13px; top: 5px">
                            <svg width="10" height="10" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16"></svg>
                            <span class="tooltiptext my-tooltip---text">
                             Rejected Count
                            </span>
                          </span> -->
                        </p>
                        <p class="relative pl-2">Post Offer : {{ x.postOfferStage }} <span class="report-red" pTooltip="Rejected Count"> [{{x.postOfferRejectedCount}}] </span>
                          <!-- <span class="tooltip ttoooltipp absolute" style="left: -13px; top: 5px">
                            <svg width="10" height="10" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16"></svg>
                            <span class="tooltiptext my-tooltip---text">
                             Rejected Count
                            </span>
                          </span> -->
                        </p>
                        <p class="relative pl-2">Pre Offer : {{ x.preOfferStage }} <span class="report-red" pTooltip="Rejected Count"> [{{x.preOfferRejectedCount}}] </span>
                          <!-- <span class="tooltip ttoooltipp absolute" style="left: -13px; top: 5px">
                            <svg width="10" height="10" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16"></svg>
                            <span class="tooltiptext my-tooltip---text">
                             Rejected Count
                            </span>
                          </span> -->
                        </p>
                      </div>
                    </div>
                    <div class="left-div right-div">
                      <div class="left-div-items jah_tooltip reports-tool-tip">
                        <div class="d-flex align-items-center left-div-items">
                          <p class="pl-3 text_overflow">Dpt : {{ x.department || "--" }}</p>

                          <div
                            class="tooltip ttoooltipp reports-border-leeftt">
                            <p class="pl-3 pointer" pTooltip="{{x.createdEmail}}"  tooltipPosition="bottom">
                              Created by : {{ x.createdBy || "--" }}
                            </p>
                            <!-- <span class="tooltiptext" id="trf-tooltip">
                              <p>{{ x.createdEmail }}</p>
                            </span> -->
                          </div>
                          <p class="px-3">
                            Created on : {{ x.createdOn | date : "MMM dd,YYYY" }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #noResults>
            <p
              class="text-center no__records__found"
              style="color: #764fdb !important; margin-top: 30px; position : unset !important;"
            >
              No Records Found
            </p>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </main>
</ng-container>
<!-- new reports screen -->
<!-- new reports screen -->

<main class="reports-two-main p-4">
  <!-- style="width:93%" -->

  <ng-container *ngIf="brandWiseActiveJobsScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">School Wise Active Jobs/Vacancies</h1>
        <div class="d-flex">
          <h1 class="fs18 fw600 figma_blue_color">
            Total Jobs : {{ totalNumberOfJobs }}
          </h1>
          <h1 class="fs18 fw600 figma_blue_color ml-3">
            Total Vacancies : {{ activeTotalPositions }}
          </h1>
        </div>
      </div>
      <div class="active-jobs-data-part row pl-3 pointer">
        <ng-container *ngFor="let x of totalReportsDataActive; let i = index">
          <div
            class="active-jobs-data-single-part"
            [ngClass]="{ reportsCardActiveClass: isActive1(i) }"

            >
            <!-- [ngClass]="{'reportsCardActiveClass': activeCardIndex === i}" -->
            <div class="d-flex align-items-center position-relative mb-2">
              <label
              class="fs12 fw500 pointer figma_blue_color underline"
              (click)="toggleActiveCard1(i, x.brandName);"
            data-toggle="modal"
            data-target="#staticBackdropBrandActive"
              >
                {{ x.brandName }}
              </label>
              <!-- (click)="brandDataInfo(x, 1, i); toggleActiveCard1(i);$event.stopPropagation();" -->
              <img
              (click)="brandDataInfo(x, 1, i); toggleActiveCard1(i)"
                *ngIf="isActive1(i)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                src="./assets/img/white-reports.svg"
                alt="reports"
              />
              <!-- (click)="brandDataInfo(x, 1, i); toggleActiveCard1(i);$event.stopPropagation();" -->
              <img
              (click)="brandDataInfo(x, 1, i); toggleActiveCard1(i)"
                *ngIf="!isActive1(i)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                src="./assets/img/new-reports-icon 1.svg"
                alt="reports"
              />
            </div>
            <div (click)="brandDataInfo(x, 1, i); toggleActiveCard1(i)">
            <h4 class="fw500 fs12 d-flex align-items-center">
              <div class="report-box"></div>
              Jobs - {{ x.totalBrandCount }}
            </h4>
            <h4 class="fw500 fs12 d-flex align-items-center mt-2">
              <div class="report-box report-box-2"></div>
              Vacancies - {{ x.noofposition }}
            </h4>
          </div>
        </div>
        </ng-container>

        <!-- brandwise label popup -->
        <div
          class="modal fade reports-country-modal"
          id="staticBackdropBrandActive"
          data-backdrop="static"
          data-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropBrandActiveLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h5> {{brandna_name}}  </h5>
              <div class="modal-body">
                <div class="reports-table-design reports-modal-table mt-3">
                  <div class="div-table-head d-flex align-items-center">
                    <div class="cell">Country</div>
                    <div class="cell">Active Jobs</div>
                    <div class="cell">Vacancies</div>
                    <div class="cell" style="min-width:110px; width:110px">View Reports</div>
                  </div>

                  <div
                    class="div-table-body d-flex align-items-center"
                    *ngFor="let x of activeJobsnew"
                  >
                    <div class="cell">{{ x.countryName }}</div>
                    <div class="cell">{{ x.countryCount }}</div>
                    <div class="cell">
                      {{ x.countryPosition }}
                    </div>
                    <div class="cell d-flex justify-content-center" style="min-width:110px; width:110px">
                      <img
                      (click)="toggleNewReportsTableActivenew(x.countryName,1)"
                      data-dismiss="modal"
                      aria-label="Close"
                      class="pointer close"
                      src="./assets/img/new-reports-icon 1.svg"
                      alt="reports"
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- brandwise label popup -->
      </div>
      <div class="loader" *ngIf="reportsShowLoader"></div>
    </section>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="brandWiseActiveJobsScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <h1 class="fs18 fw600 mb-3">School wise Active Jobs</h1>
      <table class="reports-table-two-design w-100">
        <thead class="d-flex z1">
          <tr>
            <th>GIIS</th>
            <th>OWIS</th>
            <th>WITTY</th>
            <th>HARRODS</th>
            <th>DWIGHT</th>
            <th>GLENDALE</th>
            <th>REGENT</th>
            <th>DOMUSCHOLA</th>
            <th>CIS</th>
            <th>VIKAASA</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody class="">
          <ng-container *ngFor="let x of reportsData; let i = index">
            <div class="position-relative">
              <tr>
                <td>{{ x.giis || "--" }}</td>
                <td>{{ x.owis || "--" }}</td>
                <td>{{ x.witty || "--" }}</td>
                <td>{{ x.harrods || "--" }}</td>
                <td>{{ x.dwight || "--" }}</td>
                <td>{{ x.glendale || "--" }}</td>
                <td>{{ x.regent || "--" }}</td>
                <td>{{ x.domuschola || "--" }}</td>
                <td>{{ x.CIS || "--" }}</td>
                <td>{{ x.Vikaasa || "--" }}</td>
                <td>{{ x.total || "--" }}</td>
              </tr>
            </div>
          </ng-container>
        </tbody>
      </table>
    </section>
  </ng-container>
  <ng-container *ngIf="brandWiseClosedJobsScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <h1 class="fs18 fw600 mb-3">School wise Closed Jobs</h1>
      <table class="reports-table-two-design">
        <thead class="d-flex z1">
          <tr>
            <th>GIIS</th>
            <th>OWIS</th>
            <th>WITTY</th>
            <th>HARRODS</th>
            <th>DWIGHT</th>
            <th>GLENDALE</th>
            <th>REGENT</th>
            <th>DOMUSCHOLA</th>
            <th>CIS</th>
            <th>VIKAASA</th>
            <th>TOTAL</th>
            <th>TOTAL</th>
            <th>TOTAL</th>
            <th>TOTAL</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody class="">
          <ng-container *ngFor="let x of reportsData; let i = index">
            <div class="position-relative">
              <tr>
                <td>{{ x.giis || "--" }}</td>
                <td>{{ x.owis || "--" }}</td>
                <td>{{ x.witty || "--" }}</td>
                <td>{{ x.harrods || "--" }}</td>
                <td>{{ x.dwight || "--" }}</td>
                <td>{{ x.glendale || "--" }}</td>
                <td>{{ x.regent || "--" }}</td>
                <td>{{ x.domuschola || "--" }}</td>
                <td>{{ x.CIS || "--" }}</td>
                <td>{{ x.Vikaasa || "--" }}</td>
                <td>{{ x.total || "--" }}</td>
                <td>{{ x.total || "--" }}</td>
                <td>{{ x.total || "--" }}</td>
                <td>{{ x.total || "--" }}</td>
                <td>{{ x.total || "--" }}</td>
              </tr>
            </div>
          </ng-container>
        </tbody>
      </table>
    </section>
  </ng-container> -->
    <!-- <ng-container *ngIf="brandWiseClosedJobsScreen">
    <div class="loader" *ngIf="reportsShowLoader">

    </div> -->
    <section class="mt-4">
      <!-- <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5> -->
      <div class="d-flex align-items-center justify-content-between mb-3 flex-wrap">
        <h1 class="fs18 fw600">School Wise Closed Jobs</h1>
        <div class="d-flex">
          <h1 class="fs18 fw600 figma_blue_color">
            Total Jobs : {{ totalClosedBrandCount }}
          </h1>
          <h1 class="fs18 fw600 figma_blue_color ml-3">
            Total Vacancies : {{ newTotalClosedJobsCount }}
          </h1>
          <!-- <h1 class="fs18 fw600 figma_blue_color ml-3">
          Total Positions : {{ totaClosedJobs.noofposition }}
        </h1> -->
        </div>
      </div>

      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of totalReportsDataClosed; let i = index">
          <div
            class="active-jobs-data-single-part pointer"
            [ngClass]="{ reportsCardActiveClass: isActive2(x.brandName)}"
            >
            <!-- (click)="brandDataInfo(x, 2, i)" -->
            <!-- [ngClass]="{'reportsCardActiveClass': activeCardIndex2 === i}" -->
            <div class="d-flex align-items-center position-relative mb-2">
              <label
              class="fs12 fw500 pointer figma_blue_color underline"
              data-toggle="modal"
              (click)="toggleActiveCard2(x.brandName)"
              data-target="#staticBackdropBrandClosed"
              >
                {{ x.brandName }}
              </label>
              <!-- ;$event.stopPropagation(); -->
              <img
                *ngIf="isActive2(x.brandName)"
                (click)="brandDataInfo(x, 2, i); toggleActiveCard2(x.brandName)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                src="./assets/img/white-reports.svg"
                alt="reports"
              />
              <!-- ;$event.stopPropagation(); -->
              <img
                *ngIf="!isActive2(x.brandName)"
                (click)="brandDataInfo(x, 2, i); toggleActiveCard2(x.brandName)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                src="./assets/img/new-reports-icon 1.svg"
                alt="reports"
              />
            </div>
            <div (click)="brandDataInfo(x, 2, i); toggleActiveCard2(x.brandName)">
            <h4 class="fw500 fs12 d-flex align-items-center">
              <div class="report-box"></div>
              Jobs - {{ x.totalBrandCount }}
            </h4>
            <h4 class="fw500 fs12 d-flex align-items-center mt-2">
              <div class="report-box report-box-2"></div>
              Vacancies - {{ x.noofposition }}
            </h4>
            <!-- <h4 class="fw500 fs12">Positions - {{ x.noofposition }}</h4> -->
          </div></div>
        </ng-container>
      </div>
    </section>

    <!-- brandwise closed label popup -->
    <div
      class="modal fade reports-country-modal"
      id="staticBackdropBrandClosed"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropBrandClosedLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <h5> {{brandna_name}}  </h5>
          <div class="modal-body">
            <div class="reports-table-design reports-modal-table mt-3">
              <div class="div-table-head d-flex align-items-center">
                <div class="cell">Country</div>
                <div class="cell">Closed Jobs</div>
                <div class="cell" style="min-width:110px; width:110px">View Reports</div>
                <!-- <div class="cell">Vacancies</div> -->
              </div>

              <div
                class="div-table-body d-flex align-items-center"
                *ngFor="let x of closedjobapicount"
              >
                <div class="cell">{{ x.countryName }}</div>
                <div class="cell">
                  {{ x.countryCount }}
                </div>
                <div class="cell d-flex justify-content-center" style="min-width:110px; width:110px">
                  <img
                  (click)="toggleNewReportsTableActivclosed(x.countryName,2)"
                  data-dismiss="modal"
                  aria-label="Close"
                  class="pointer close"
                  src="./assets/img/new-reports-icon 1.svg"
                  alt="reports"
                />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- brandwise closed label popup -->

  </ng-container>
  <section id="sectionId" class="mb-5"></section>

  <div class="loader" *ngIf="reportsShowLoaderTwo"></div>

  <section
    class="reports-table-design reports-table-two-design my-3 white-background p16 "
    *ngIf="toggleBrandwiseTable"
  >
  <div class="d-flex align-items-center justify-content-between mb-3 flex-wrap">
    <h1 class="fw600 fs16 mb-2" *ngIf="tableSectionTwo">
      School Wise Active Jobs/Vacancies - {{ reportBrandName }} - {{reportCountry}}
    </h1>

    <h1 class="fw600 fs16" *ngIf="!tableSectionTwo">
      School Wise Closed Jobs - {{ reportBrandName }} - {{ reportCountry }}
    </h1>

    <div class="relative figma_search_part d-flex justify-content-end">
      <input
        id="reports-new-search"
        class="ml-auto"
        placeholder="Search"
        [(ngModel)]="brandActiveReportsSearchh"
        (keyup)="GetActiveCloseBrandJobsTrackerReportbysearchitems($event.target.value)"
      />
      <img
        id="search-input--icons"
        class="absolute figma__search__Icon"
        src="./assets/img/search-icon.svg"
        alt="search"
      />
      <img
        id="search-input--icons-2"
        class="job-searchh-new position-absolute pointer"
        src="./assets/img/figma-close-iconBlue-1.svg"
        (click)="removeValue()"
        alt="cross"
        *ngIf="brandActiveReportsSearchh"
      />
    </div>
    <button
              type="button"
              class="new-download-report-button ml-2 d-flex"
              (click)="getActiveExcelReports()"
            >
              <img
                class="mr-1 img-fluid"
                src="./assets/img/document-download.svg"
                alt="download"
              />
              Download
            </button>
  </div>
  <div class="loader" *ngIf="reportsShowLoaderNew"></div>
    <div class="reports_table_scroll table_scrollbar" *ngIf="!reportsShowLoaderNew">
      <table class="reports-table-design">
        <thead
          class="div-table-head"
          *ngIf="getReportData.length > 0;">
          <tr class="d-flex align-items-center justify-content-between">
            <th class="cell">Sl.No</th>
            <th class="cell">Schools</th>
            <th class="cell">Country</th>
            <th class="cell">Campus</th>
            <th class="cell">Job ID</th>
            <th class="cell">Job Title</th>
            <th class="cell">Vacancies</th>
            <th class="cell">
              Category <br />
              (Ac/Non-Ac)
            </th>
            <th class="cell">
              New / <br />
              Replacement
            </th>
            <th class="cell" class="cell d-flex">Job Status</th>
          </tr>
          <!--  else noResultsTwo -->

          <!-- <div class="cell">Job Created By</div>
            <div class="cell">Created On</div>
            <div class="cell">Applicants</div>
            <div class="cell text-center">New</div>
            <div class="cell text-center">Screening</div>
            <div class="cell text-center">Interview</div>
            <div class="cell text-center">Offer</div>
            <div class="cell text-center">Post-Offer</div> -->
        </thead>
        <tbody class="">
          <ng-container
            *ngFor="
              let x of getReportData
              let i = index
            "
          >
            <tr class="">
              <div class="div-table-body d-flex align-items-center justify-content-between">
                <td class="cell">{{ i + 1 }}</td>
                <td class="cell">{{ x.school }}</td>
                <td class="cell">{{ x.country }}</td>
                <td class="cell">{{ x.campus }}</td>
                <td class="cell">{{ x.jobId }}</td>
                <td class="cell text-overflow">{{ x.jobTitle }}</td>
                <td class="cell text-center">{{ x.vacancies }}</td>
                <td class="cell">{{ x.category }}</td>
                <td class="cell">{{ x.type || "--" }}</td>
                <td class="cell text-center">
                  <div
                    class="reports-status"
                    [ngClass]="{
                      activeReports: x.status === 'Active',
                      closedReports: x.status === 'Close'
                    }"
                  >
                    {{ x.status }}
                  </div>
                </td>
              </div>

              <!-- <div class="cell">{{ x.createdBy }}</div>
                <div class="cell">
                  {{ x.createdOn | date : "MMM dd,YYYY" }}
                </div>
                <div class="cell text-center">{{ x.noOfApplicants || "0" }}</div>
                <div class="cell text-center">{{ x.newStage || "0" }}</div>
                <div class="cell text-center">{{ x.screeningStage || "0" }}</div>
                <div class="cell text-center">{{ x.interviewStage || "0" }}</div>
                <div class="cell text-center">{{ x.offeredStage || "0" }}</div>
                <div class="cell text-center">{{ x.postOfferStage || "0" }}</div> -->
                <div
                  class="tracker-report-mid-div d-flex align-items-center justify-content-between"
                >
                  <div class="left-div">
                    <!-- Applicants: 0 | New: 0 | Screening: 0 | Interview: 0 | Offer: 0 | Post Offer: 0 -->
                    <div class="d-flex align-items-center left-div-items">
                      <p class="pr-3 ">Applicants : {{ x.noOfApplicants }}</p>
                      <p class="px-3 ">New : {{ x.newStage }}</p>
                      <p class="px-3 ">Screening : {{ x.screeningStage }}</p>
                      <p class="px-3 ">Interview : {{ x.interviewStage }}</p>
                      <p class="px-3 ">Offer : {{ x.offeredStage }}</p>
                      <p class="px-3 ">Post Offer : {{ x.postOfferStage }}</p>
                    </div>
                  </div>
                  <div class="left-div right-div">
                    <div class="left-div-items jah_tooltip reports-tool-tip">
                      <div class="d-flex align-items-center left-div-items">
                        <p class="pl-3">Dpt : <span class="text-overflow"> {{ x.department || "--" }} </span> </p>

                        <div
                          class="tooltip ttoooltipp reports-border-leeftt"
                          id="reports-tooltip-main"
                        >
                          <p class="pl-3 pointer" pTooltip="{{x.createdEmail}}" tooltipPosition="bottom">
                            Created by : <span class="text-overflow"> {{ x.createdBy || "--" }} </span>
                          </p>
                          <!-- <span class="tooltiptext" id="trf-tooltip">
                            <p>{{ x.createdEmail }}</p>
                          </span> -->
                        </div>
                        <p class="px-3">
                          Created on : {{ x.createdOn | date : "MMM dd,YYYY" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <!-- <ng-template #noResultsTwo> -->
        <p *ngIf="!getReportData.length"
          class="text-center no__records__found"
          style="color: #764fdb !important; margin-top: 30px; position : unset !important;"
        >
          No Records Found
        </p>
      <!-- </ng-template> -->
    </div>
  </section>

  <!-- jobs wise screen -->
  <ng-container *ngIf="JobsWiseActiveJobsScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">Country Wise Active Jobs/Vacancies</h1>
        <div class="d-flex">
          <h1 class="fs18 fw600 figma_blue_color">
            Total Jobs : {{ totalActivecountryJobsCountt }}
          </h1>
          <h1 class="fs18 fw600 figma_blue_color ml-3">
            Total Vacancies : {{ jobstotalNoOfPositionss }}
          </h1>
        </div>
      </div>
      <!-- (click)="toggleSingleCountryWiseReports(x, 1, i, x.countryName)" -->
      <!-- (click)="countInd(x, 1, i, x.countryName)" -->
      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of jobwiseActiveJobs; let i = index">
          <div
            class="active-jobs-data-single-part pointer position-relative"
            [ngClass]="{ reportsCardActiveClass: isActive3(i) }"
            >
            <!-- [ngClass]="{'reportsCardActiveClass': activeCardIndex3 === i}" -->
            <div class="d-flex align-items-center position-relative mb-2">
              <label
              class="fs12 fw500 pointer figma_blue_color underline"
              data-toggle="modal"
              data-target="#staticBackdrop"
              (click)="countIndnewwww(x, 1, i, x.countryName); toggleActiveCard3(i)">
                {{ x.countryName }}
              </label>
              <!-- *ngIf="activeCardIndex3 === -1" -->
              <!-- ;$event.stopPropagation(); -->
              <img
                *ngIf="!isActive3(i)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                (click)="
                  getCountryWisePopUptableData(x, 1, x.countryName, i);
                  toggleActiveCard3(i)
                "
                src="./assets/img/new-reports-icon 1.svg"
                alt="reports"
              />
              <!-- *ngIf="activeCardIndex3 === i" -->
               <!-- ;$event.stopPropagation(); -->
              <img
                *ngIf="isActive3(i)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                (click)="
                  getCountryWisePopUptableData(x, 1, x.countryName, i);
                  toggleActiveCard3(i)
                "
                src="./assets/img/white-reports.svg"
                alt="reports"
              />
            </div>
            <div (click)="
            getCountryWisePopUptableData(x, 1, x.countryName, i);
            toggleActiveCard3(i);
          ">
            <h4 class="fw500 fs12 d-flex align-items-center">
              <div class="report-box"></div>
              Jobs - {{ x.totalcountryCount }}
            </h4>
            <h4 class="fw500 fs12 d-flex align-items-center mt-2">
              <div class="report-box report-box-2"></div>
              Vacancies - {{ x.noofposition }}
            </h4>
          </div>
        </div>

          <!-- country active jobs modal -->
          <!-- Button trigger modal -->
          <!-- Modal -->
          <div
            class="modal fade reports-country-modal"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <h5> {{newCountryName}}  </h5>
                <div class="modal-body">
                  <div class="reports-table-design reports-modal-table mt-3">
                    <div class="div-table-head d-flex align-items-center">
                      <div class="cell">School</div>
                      <div class="cell">Active Jobs</div>
                      <div class="cell">Vacancies</div>
                      <div class="cell" style="min-width:110px; width:110px">
                        View Reports
                      </div>
                    </div>

                    <div
                      class="div-table-body d-flex align-items-center"
                      *ngFor="let x of brand_count"
                    >
                      <div class="cell">{{ x.brandName }}</div>
                      <div class="cell">{{ x.jobIdCount }}</div>
                      <div class="cell d-flex align-items-center">
                        {{ x.noofposition }}
                      </div>
                      <div class="cell d-flex justify-content-center" style="min-width:110px; width:110px">
                        <img
                          (click)="toggleNewReportsTable(x.brandName,1)"
                          data-dismiss="modal"
                          aria-label="Close"
                          class="pointer close"
                          src="./assets/img/new-reports-icon 1.svg"
                          alt="reports"
                        />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- country active jobs modal -->
        </ng-container>
      </div>
      <div class="loader" *ngIf="reportsShowLoader"></div>
    </section>

    <section class="mt-4">
      <div class="d-flex align-items-center justify-content-between mb-3 flex-wrap">
        <h1 class="fs18 fw600">Country Wise Closed Jobs</h1>
        <div class="d-flex">
          <h1 class="fs18 fw600 figma_blue_color">
            Total Jobs : {{ totalClosedcountryJobsCountt }}
          </h1>
          <h1 class="fs18 fw600 figma_blue_color ml-3">
            Total Vacancies : {{ totalNoOfPositionData }}
          </h1>
        </div>
      </div>
      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of jobwiseClosedJobs; let i = index">
          <div
            class="active-jobs-data-single-part pointer"
            [ngClass]="{ reportsCardActiveClass: isActive4(i) }"
            >
            <div class="d-flex align-items-center position-relative mb-2">
              <label
              data-toggle="modal"
              data-target="#staticBackdrop1"
              (click)="
                countIndnewwww(x, 1, i, x.countryName); toggleActiveCard4(i)
              "
                class="fs12 fw500 pointer figma_blue_color underline">
                {{ x.countryName }}
              </label>
              <img
                *ngIf="!isActive4(i)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                (click)="
                  getCountryWisePopUptableData(x, 2, x.countryName);
                  toggleActiveCard4(i)
                "
                src="./assets/img/new-reports-icon 1.svg"
                alt="reports"
              />
              <!-- ;$event.stopPropagation(); -->
              <img
                *ngIf="isActive4(i)"
                class="position-absolute new-reports-blue pointer w--18-reports"
                (click)="
                  getCountryWisePopUptableData(x, 2, x.countryName);
                  toggleActiveCard4(i)
                "
                src="./assets/img/white-reports.svg"
                alt="reports"
              />
            </div>
            <div (click)="
            getCountryWisePopUptableData(x, 2, x.countryName);
            toggleActiveCard4(i)
          ">
            <h4 class="fw500 fs12 d-flex align-items-center">
              <div class="report-box"></div>
              Jobs - {{ x.totalcountryCount }}
            </h4>
            <h4 class="fw500 fs12 d-flex align-items-center mt-2">
              <div class="report-box report-box-2"></div>
              Vacancies - {{ x.noofPosition }}
            </h4>
            <!-- <h4 class="fw500 fs12">Positions - {{ x.noofposition }}</h4> -->
          </div></div>
          <!-- country closed jobs modal -->
          <div
            class="modal fade reports-country-modal"
            id="staticBackdrop1"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdrop1Label"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <h5> {{newCountryName}}  </h5>
                <div class="modal-body">
                  <div class="reports-table-design reports-modal-table mt-3">
                    <div class="div-table-head d-flex align-items-center">
                      <div class="cell">School</div>
                      <div class="cell">Closed Jobs</div>
                      <div class="cell" style="min-width:110px; width:110px">View Reports</div>
                    </div>

                    <div
                      class="div-table-body d-flex align-items-center"
                      *ngFor="let x of closedJobs"
                    >
                      <div class="cell">{{ x.brandName }}</div>
                      <div class="cell">
                        {{ x.jobIdCount }}
                      </div>
                      <div class="cell d-flex justify-content-center" style="min-width:110px; width:110px">
                        <img
                          (click)="toggleNewReportsTableclosed(x.brandName,2)"
                          data-dismiss="modal"
                          aria-label="Close"
                          class="pointer close"
                          src="./assets/img/new-reports-icon 1.svg"
                          alt="reports"
                        />
                        </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          </div> -->
              </div>
            </div>
          </div>
          <!-- country closed jobs modal -->
        </ng-container>
      </div>
    </section>
  </ng-container>

  <section id="sectionId2" class="mb-5"> </section>

  <section
    class="reports-table-design reports-table-two-design mt-5 white-background p16 card"
    *ngIf="toggleNewReportsTableSection"
  >
  <div class="d-flex align-items-center justify-content-between mb-2 flex-wrap">
    <h1 class="fw600 fs16 mb-2" *ngIf="tableSectionTwo">
      Country Wise Active Jobs/Vacancies - {{ countryReportName }} - {{ countryBrandName }}
    </h1>
    <h1 class="fw600 fs16 mb-2" *ngIf="!tableSectionTwo">
      Country Wise Closed Jobs - {{ countryReportName }} - {{ countryBrandName }}
    </h1>
    <div class="relative figma_search_part d-flex justify-content-end">
      <input
        id="reports-new-search"
        class="ml-auto"
        placeholder="Search"
        [(ngModel)]="countryWiseReportsSearchh"
        (ngModelChange)="showCross()"
        (keyup)="GetActiveCloseCountryBrandJobsTrackerReportbysearchitems($event.target.value)"
      />
      <img
        id="search-input--icons"
        class="absolute figma__search__Icon"
        src="./assets/img/search-icon.svg"
        alt="search"
      />
      <img
        id="search-input--icons-2"
        class="job-searchh-new position-absolute pointer"
        src="./assets/img/figma-close-iconBlue-1.svg"
        (click)="removeValue()"
        alt="cross"
        *ngIf="blueCrossMark"
      />
    </div>
    <button
              type="button"
              class="new-download-report-button ml-2 d-flex"
              (click)="getCloseExcelReports()"
            >
              <img
                class="mr-1 img-fluid"
                src="./assets/img/document-download.svg"
                alt="download"
              />
              Download
            </button>
  </div>
    <div class="reports_table_scroll table_scrollbar">

      <div class="loader" *ngIf="reportsLoader"></div>
      <ng-container *ngIf="!reportsLoader">
        <table class="reports-table-design">
          <thead
            class="div-table-head"
            *ngIf="
              (getReport ).length > 0;
              else noResultsThree
            "
          >
          <tr class="d-flex w-100 justify-content-between">
            <th class="cell">Sl.No</th>
            <th class="cell">Schools</th>
            <th class="cell">Country</th>
            <th class="cell">Campus</th>
            <th class="cell">Job ID</th>
            <th class="cell">Job Title</th>
            <th class="cell">Vacancies</th>
            <th class="cell">
              Category <br />
              (Ac/Non-Ac)
            </th>
            <th class="cell">
              New/ <br />
              Replacement
            </th>
            <th class="cell" class="cell d-flex">Job Status</th>
          </tr>

            <!-- <div class="cell">Job Created By</div>
            <div class="cell">Created On</div>
            <div class="cell">Applicants</div>
            <div class="cell text-center">New</div>
            <div class="cell text-center">Screening</div>
            <div class="cell text-center">Interview</div>
            <div class="cell text-center">Offer</div>
            <div class="cell text-center">Post-Offer</div> -->
          </thead>
          <tbody class="div-table-body-main ">
            <ng-container
              *ngFor="
                let x of newCountryWiseActiveTable

                let i = index
              "
            >
              <tr class="">
                <div class="div-table-body d-flex align-items-center justify-content-between">
                  <td class="cell">{{ i + 1 }}</td>
                  <td class="cell">{{ x.school }}</td>
                  <td class="cell">{{ x.country }}</td>
                  <td class="cell">{{ x.campus }}</td>
                  <td class="cell">{{ x.jobId }}</td>
                  <td class="cell text-overflow">{{ x.jobTitle }}</td>
                  <td class="cell text-center">{{ x.vacancies }}</td>
                  <td class="cell">{{ x.category }}</td>
                  <td class="cell">{{ x.type || "--" }}</td>
                  <td class="cell text-center">
                    <div
                      class="reports-status"
                      [ngClass]="{
                        activeReports: x.status === 'Active',
                        closedReports: x.status === 'Close'
                      }"
                    >
                      {{ x.status }}
                    </div>
                  </td>
                </div>

                <!-- <div class="cell">{{ x.createdBy }}</div>
                <div class="cell">
                  {{ x.createdOn | date : "MMM dd,YYYY" }}
                </div>
                <div class="cell text-center">{{ x.noOfApplicants || "0" }}</div>
                <div class="cell text-center">{{ x.newStage || "0" }}</div>
                <div class="cell text-center">{{ x.screeningStage || "0" }}</div>
                <div class="cell text-center">{{ x.interviewStage || "0" }}</div>
                <div class="cell text-center">{{ x.offeredStage || "0" }}</div>
                <div class="cell text-center">{{ x.postOfferStage || "0" }}</div> -->
                <div
                  class="tracker-report-mid-div d-flex align-items-center justify-content-between"
                >
                  <div class="left-div">
                    <!-- Applicants: 0 | New: 0 | Screening: 0 | Interview: 0 | Offer: 0 | Post Offer: 0 -->
                    <div class="d-flex align-items-center left-div-items">
                      <p class="pr-2">Applicants : {{ x.noOfApplicants }}</p>
                      <p class="px-2">New : {{ x.newStage }}</p>
                      <p class="px-2">Screening : {{ x.screeningStage }}</p>
                      <p class="px-2">Interview : {{ x.interviewStage }}</p>
                      <p class="px-2">Offer : {{ x.offeredStage }}</p>
                      <p class="px-2">Post Offer : {{ x.postOfferStage }}</p>
                    </div>
                  </div>
                  <div class="left-div right-div">
                    <div class="left-div-items jah_tooltip reports-tool-tip">
                      <div class="d-flex align-items-center left-div-items">
                        <p class="pl-3 text_overflow">Dpt : {{ x.department || "--" }}</p>

                        <div
                          class="tooltip ttoooltipp reports-border-leeftt px-2"
                          id="reports-tooltip-main"
                        >
                          <p class="pointer">
                            Created by : {{ x.createdBy || "--" }}
                          </p>
                          <span class="tooltiptext" id="trf-tooltip">
                           {{ x.createdEmail }}
                          </span>
                        </div>
                        <p class="px-2">
                          Created on : {{ x.createdOn | date : "MMM dd,YYYY" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noResultsThree>
        <p
          class="text-center no__records__found"
          style="color: #764fdb !important; margin-top: 30px; position : unset !important;"
        >
          No Records Found
        </p>
      </ng-template>
    </div>
  </section>

  <!-- jobs wise screen -->

  <ng-container *ngIf="applicantsCountScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">Applicants Stage Wise Count</h1>
        <h1 class="fs18 fw600 figma_blue_color">Total : {{ allcount }}</h1>
      </div>
      <!-- <div class="loader" *ngIf="showLoader"></div> -->
      <div class="active-jobs-data-part row pl-3">
        <!-- <ng-container *ngFor="let x of interviewCountArray"> -->
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> All </label>
          <h4 class="fw500 fs12">{{ allcount }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> New </label>
          <h4 class="fw500 fs12">{{ newCount }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Screening </label>
          <h4 class="fw500 fs12">{{ screeningcount }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Interview </label>
          <h4 class="fw500 fs12">{{ interviewcount }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Pre-Offer </label>
          <h4 class="fw500 fs12">{{ preOffercount }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Offered </label>
          <h4 class="fw500 fs12">{{ offeredcount }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Post Offer </label>
          <h4 class="fw500 fs12">{{ postOfferCOunt }}</h4>
        </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Rejected </label>
          <h4 class="fw500 fs12">{{ rejectedCount }}</h4>
        </div>
        <!-- </ng-container> -->
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="applicantsCountryCountScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">Applicants Country Wise Report</h1>
        <h1 class="fs18 fw600 figma_blue_color">
          Total : {{ totalCountryAppCount }}
        </h1>
      </div>
      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of countryWiseReports">
          <div class="active-jobs-data-single-part">
            <label class="mb-0 fs14 fw600"> {{ x.country }} </label>
            <h4 class="fw500 fs12">{{ x.jobIdCount }}</h4>
          </div>
        </ng-container>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="websiteAppliedScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">Website - Applied</h1>
        <h1 class="fs18 fw600 figma_blue_color">
          Total : {{ totalCountryAppCount }}
        </h1>
      </div>
      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of countryWiseReports">
          <div class="active-jobs-data-single-part">
            <label class="mb-0 fs14 fw600"> {{ x.country }} </label>
            <h4 class="fw500 fs12">{{ x.jobIdCount }}</h4>
          </div>
        </ng-container>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="websiteUploadedResumeScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">Website - Uploaded Resume</h1>
        <h1 class="fs18 fw600 figma_blue_color">
          Total : {{ totalCountryAppCount }}
        </h1>
      </div>
      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of countryWiseReports">
          <div class="active-jobs-data-single-part">
            <label class="mb-0 fs14 fw600"> {{ x.country }} </label>
            <h4 class="fw500 fs12">{{ x.jobIdCount }}</h4>
          </div>
        </ng-container>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="resumeBankScreen">
    <section>
      <h5
        class="fs14 fw600 mb-3 figma_blue_color back-to-jobs pointer width-fit"
        (click)="backToReports()"
      >
        <i class="icon-angle-down angle-rotate-d"></i> Back to Reports
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="fs18 fw600">Applicant Pool</h1>
        <h1 class="fs18 fw600 figma_blue_color">
          Total : {{ resumeTotalCount }}
        </h1>
      </div>
      <div class="active-jobs-data-part row pl-3">
        <ng-container *ngFor="let x of resumeData">
          <div class="active-jobs-data-single-part">
            <label class="mb-0 fs14 fw600"> {{ x.name }} </label>
            <h4 class="fw500 fs12">{{ x.totalCount }}</h4>
          </div>
          <!-- <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> Website Enqueries </label> -->
          <!-- <h4 class="fw500 fs12"> {{x.brandSccore}} </h4> -->
          <!-- </div>
        <div class="active-jobs-data-single-part">
          <label class="mb-0 fs14 fw600"> HR </label> -->
          <!-- <h4 class="fw500 fs12"> {{x.brandSccore}} </h4> -->
          <!-- </div> -->
        </ng-container>
      </div>
    </section>
  </ng-container>
</main>
