import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class commonService {

  public doc: any = [];
  public job: any = [];
  public nationality: any = [];
  public department: any = [];
  public roles: any = [];
  public resident: any = [];
  public level: any = [];
  public degree: any;
  public grade: any;
  public preSetPermissions: any = [];
  public phoneCode: any = [];
  public currencyCode: any = [];
  public criteriaData: any;
  public userId: any;
  headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache');
  userDetails: any;
  // headers = new HttpHeaders().set('content-type','application/json');

  constructor(private http: HttpClient) {

    let user = localStorage.getItem('userDetails');
    if (user != null) {
      // let userDetails = JSON.parse(user);
      // this.userId = userDetails.userId.toString();
      // this.headers = this.headers.append('userId', this.userId);
      this.userDetails = jwtDecode(user)
      let userId = this.userDetails.UserId
      this.headers = this.headers.append('userId', userId);

    }
  }

  ngOnInit() {

  }


  getDegree(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDegree?isManagesettings=${isManagesettings}`, { headers: this.headers })
  }

  getGrade() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetGrade`, { headers: this.headers })
  }

  getDocument(isManagesettings = false) {
    // return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentType`, { headers: this.headers });
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentDetails?isManagesettings=${isManagesettings}`, { headers: this.headers });

  }

  getDocumentType(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentType?isManagesettings=${isManagesettings}`, { headers: this.headers });

  }
  GetPostOffer() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetPostOffer`, { headers: this.headers });

  }

  GetCheckListType() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCheckListType`, { headers: this.headers });

  }

  getEducationLevel(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentType?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getGradeData(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentType?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getDegreeData(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentType?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getCategories(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobcategory?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getJob() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobpostedby`, { headers: this.headers });
  }

  getNationality() {
    return this.nationality = ["India", "Aland Islands", "Albania", "Algeria", "American Samoa", "United Arab Emirates", "United States"]
  }

  getCandidateNationality() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCountries`, { headers: this.headers });
  }

  getResident() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCountries`, { headers: this.headers });
  }

  getUserDepartment() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDepartment`, { headers: this.headers });
  }

  getuserRoles() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetUserRoles`, { headers: this.headers });
  }

  getLevelOfEducation(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetEducationList?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  GetAllJobRoles(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetAllJobRoles?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getPreSetPermissions() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetsetPermission`, { headers: this.headers });
  }
  getCampusData() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCampusNames`, { headers: this.headers });
  }

  getExperience(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetExpereince?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getpostJob(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobPost?isManagesettings=${isManagesettings}`, { headers: this.headers })
  }
  getpostJobto() {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobPost`, { headers: this.headers })
  }

  getJobCategory(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobcategory?isManagesettings=${isManagesettings}`, { headers: this.headers })
  }


  getCampusUser() {
    return this.http.get(`${environment.apiUrl}/api/User/GetUserCampus?userId=${this.userId}`, { headers: this.headers });
  }

  getCampus() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCampusNames`, { headers: this.headers });
  }

  getCountry(countryname) {
    return this.http.get(`${environment.apiUrl}/api/User/GetCountryData?countryname=${countryname}`, { headers: this.headers });
  }

  getCampusNameByuserId(userId) {
    return this.http.get(`${environment.apiUrl}/api/Dashboard/GetCampusbyUserId?userId=${userId}`, { headers: this.headers });
  }


  GetBulkUploadPublishedJobNames(campusid) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetBulkUploadPublishedJobNames?CampusId=${campusid}`, { headers: this.headers });
  }



  GetBulkUploadIJPPublishedJobNames(campusid) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetBulkUploadIJPPublishedJobNames?CampusId=${campusid}`, { headers: this.headers });
  }

  GetAllCountryDetailsById(id) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetAllCountryDetailsById?id=${id}`, { headers: this.headers });
  }
  ValidateJObTitleRelated(title, id) {
    return this.http.get(`${environment.apiUrl}/api/Master/ValidateJObTitleRelated?jobtitle=${title}&id=${id}`, { headers: this.headers });
  }
  ValidateJobOnCampus(title, id) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/ValidateJobOnCampus?jobtitle=${title}&campus=${id}`, { headers: this.headers });
  }

  ValidateBrandJob(title, id) {
    return this.http.get(`${environment.apiUrl}/api/Master/ValidateBrandJob?jobname=${title}&brand=${id}`, { headers: this.headers });
  }

  GetJobtitleRealted(title, cid) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobtitleRealted?JobTitle=${title}&CountryId=${cid}`, { headers: this.headers });
  }

  ValidateJobName(title, levelId, sublevelId) {
    return this.http.get(`${environment.apiUrl}/api/Master/ValidateJobName?jobtitle=${title}&level=${levelId}&sublevel=${sublevelId}`, { headers: this.headers });
  }
  getPhoneCode() {
    return this.phoneCode = [
      { id: 1, name: "+91" },
      { id: 2, name: "+49" },
      { id: 3, name: "+65" },
      { id: 4, name: "+1" },
      { id: 5, name: "+81" }
    ]
  }
  getCurrencyCode() {
    return this.currencyCode = [
      { id: 1, name: "₹", country: "India" },
      { id: 2, name: "$", country: "USA" },
      { id: 3, name: "$", country: "Singapore" },
      { id: 4, name: "دج", country: "Algeria" },
      { id: 5, name: '€', country: 'Euro' },
      { id: 6, name: 'د.إ', country: 'UAE' }
    ]
  }

  setMinimumCriteria(data) {
    this.criteriaData = data;
  }

  getMinimumCriteria() {
    return this.criteriaData;
  }

  getLanguages(isManagesettings = false) {
    //  return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages`, { headers: this.headers });
    return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  getDepartment() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDepartment`, { headers: this.headers });
  }

  GetJobsLevelsData() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobsLevelsData`, { headers: this.headers })
  }

  GetJobsLevelsDataonboarging() {
    return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetAllJobsLevelsData`, { headers: this.headers })
  }

  getJobmanage() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNames`, { headers: this.headers });
  }

  gethyringType() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiring`, { headers: this.headers });
  }

  getEmployementType() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetEmployment`, { headers: this.headers });
  }

  getAppointmentType() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetAppointment`, { headers: this.headers });
  }

  getpreferredInd() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetIndustry`, { headers: this.headers });
  }

  getpreferredDomain() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDomain`, { headers: this.headers });
  }

  getworkPermit() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetWorkPermitType`, { headers: this.headers });
  }

  getCountries() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCountries`, { headers: this.headers });
  }

  getBrandData() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetBrandId`, { headers: this.headers });
  }

  GetDepartment() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDepartment`, { headers: this.headers });
  }
  getInterview() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewQuestionTemplate`, { headers: this.headers });
  }

  getApplicantByData(id: any) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantDetailsById?id=${id}`, { headers: this.headers });
}


getJobremuneration(id: any) {
  return this.http.get(`${environment.apiUrl}/api/Candidate/GetJobRemunerationId?id=${id}`, { headers: this.headers });
}

  getjobdata() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNames`, { headers: this.headers });
  }

  GetCampusesDetails() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCampusesDetails`, { headers: this.headers });
  }

  GetNaukriCityList() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetNaukriCityList`, { headers: this.headers });
  }

  InsertCampus(data: any) {
    return this.http.post(`${environment.apiUrl}/api/Master/InsertCampus`, data, { headers: this.headers });
  }

  getNationalityData() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetNationality`, { headers: this.headers });
  }

  insertNationalityList(data: any) {
    return this.http.post(`${environment.apiUrl}/api/Master/InsertNationalityList`, data, { headers: this.headers });
  }
  insertNationality(data: any) {
    return this.http.post(`${environment.apiUrl}/api/Master/InsertNationality`, data, { headers: this.headers });
  }

  getNationalityList() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetNationalityList`, { headers: this.headers });
  }
  ValidateNationality(id: any, name: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/ValidateNationality?id=${id}&name=${name}`, { headers: this.headers });
  }
  ValidatePopupNationality(name, id) {
    return this.http.get(`${environment.apiUrl}/api/Master/ValidateNationalityList?name=${name}&id=${id}`, { headers: this.headers });
  }
  getNationalityy() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetNationality`, { headers: this.headers });
  }

  getStagedata(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewStages?isManagesettings=${isManagesettings}`, { headers: this.headers });
  }

  GetCategoryType(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCategoryType?status=${isManagesettings}`, { headers: this.headers });
  }

  GetSkills_Category() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetSkills_Category`, { headers: this.headers });
  }
  getJobbyId(jobId) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobtitleRealted?JobTitle=${jobId}`, { headers: this.headers })
  }
  GetJahJobtitleData(jahId: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJahJobtitleData?jobTitle=${jahId}`, { headers: this.headers })
  }
  GetAllCountryDetails() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetAllCountryDetails`, { headers: this.headers });
  }
  GetSubLevelByLevelId(sId: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetSubLevelByLevelId?id=${sId}`, { headers: this.headers })
  }

  GetReportsData(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveJobsReport?userId=${id}`, { headers: this.headers })
  }

  GetInterviewStageData(userId:any) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantCount?userId=${userId}`, { headers: this.headers })
  }
  GetApplicantStageWiseCountReport(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantCount?userId=${id}`, { headers: this.headers })
  }

  GetCountryReportData(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantcountryreport?userId=${id}`, { headers: this.headers })
  }

  GetResumeReportData() {
    return this.http.get(`${environment.apiUrl}/api/Applicant/Getresumecount`, { headers: this.headers })
  }
  GetJobsReport(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsReport?userId=${id}`, { headers: this.headers })
  }

  GetJobsReportsearchItem(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsReportBySearch?searchItem=${id}`, { headers: this.headers })
  }


  GetAllRoleDetailsbysearch(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Role/GetAllRoleDetailsbysearch?searchItem=${id}`, { headers: this.headers })
  }



  GetJobsReportOnCountry(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveJobCountryReport?userId=${id}`, { headers: this.headers })
  }

  GetDocumentDetailsSearch(id:any){
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentDetailsSearch?isManagesettings=true&searchItem=${id}`, { headers: this.headers })
  }

  // sss


  GetHiringStageBySearchitems(id:any){
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStageBySearchitems?searchItem=${id}`, { headers: this.headers })
  }

  //

  GetCategoryTypeSearch(id:any){
    return this.http.get(`${environment.apiUrl}/api/Master/GetCategoryTypeSearch?searchItem=${id}`, { headers: this.headers })
  }

  //

  GetJobRemunerationSearch(id:any){
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetJobRemunerationSearch?searchItem=${id}`, { headers: this.headers })
  }


  GetReportExcel(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobsReportPdf?userId=${id}`, { headers: this.headers })
  }
  GetActiveCloseBrandJobsTrackerReport(id:any,bName:any,status:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveCloseBrandJobsTrackerReport?userId=${id}&brand=${bName}&Status=${status}`, { headers: this.headers })
  }

  // = https://gsf-career-api-dev.irepo.in/api/Jobs/GetActiveCloseCountryBrandJobsTrackerReport?userId=482&country=india&brand=giis&Status=1


  // GetActiveCloseCountryBrandJobsTrackerReport(id:any,bName:any,status:any) {
  //   return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveCloseBrandJobsTrackerReport?userId=${id}&brand=${bName}&Status=${status}`, { headers: this.headers })
  // }


  // GetcampusByid(id) {
  //   let params = new HttpParams().set("roleId", id)
  //   return this.http.post(`${environment.apiUrl}/api/Master/GetCampusbyCountry?country=${params}`, { headers: this.headers });
  // }

  GetcampusByid(id): Observable<any> {
    let params = new HttpParams().set("country", id)
    return this.http.get(`${environment.apiUrl}/api/Master/GetCampusbyCountry`, { params: params })
  }
  GetDocumentRelated(data) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentRelated?Documenttype=${data}`, { headers: this.headers });
  }
  GetJobNames() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNames`, { headers: this.headers });
  }

  interviewmapadd(data) {
    return this.http.post(`${environment.apiUrl}/api/Master/InsertInterview`, data, { headers: this.headers })
  }

  GetFilters(data) {
    return this.http.post(`${environment.apiUrl}/api/Applicant/GetApplicantFilters`, data, { headers: this.headers })
  }


  interviewmapaddget(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetInterview`, { headers: this.headers })
  }

  getRemunerationData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetJobRemuneration `, { headers: this.headers })
  }

  AddJobRemuneration(params) {
    return this.http.post(`${environment.apiUrl}/api/Candidate/AddJobRemuneration`, params, { headers: this.headers })
  }

  addEntity(params) {
    return this.http.post(`${environment.apiUrl}/api/Master/InsertEntity`, params, { headers: this.headers })
  }

  getEntityData() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetEntity`, { headers: this.headers })
  }

  GetJobApprovalHierarchyById(tid: any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobApprovalHierarchyById?id=${tid}`, { headers: this.headers });
  }
  GetSkillNameById(data) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetSkillNameById?id=${data}`, { headers: this.headers });
  }

  getScheduleLogin(id: any, uid: any, passCode: any) {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetHiringDetailsByuser?userid=${id}&username=${uid}&passward=${passCode}`, { headers: this.headers });
  }
  GetPublishedJobDetails() {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetPublishedJobDetails`, { headers: this.headers });
  }



  getArvicheJobList() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNames`, { headers: this.headers });
  }

  getIJPJobList(userid) {
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJP_ReferralJobDetailsforjobs?userId=${userid}`, { headers: this.headers });
  }

  GetJobCampus(id) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobCampus?jobId=${id}`, { headers: this.headers });
  }

  GetPublishedJobNames(userId) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetPublishedJobNames?userId=${userId}`, { headers: this.headers });
  }


  getEnquiryData(){
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantResumeData`, {headers: this.headers });
  }

  trfTabsCount(uid:any){
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTrfCount?UserId=${uid}`, {headers: this.headers });
  }

  ApplicantResumeNames(){
    return this.http.get(`${environment.apiUrl}/api/Applicant/ApplicantResumeNames`, {headers: this.headers });
  }
  UpdateJobPostData(data) {
    return this.http.post(`${environment.apiUrl}/api/Jobs/UpdateJobPostData`, data, { headers: this.headers })
  }
  GetBrandname(){
    return this.http.get(`${environment.apiUrl}/api/Master/GetBrandname/GetBrandname`, {headers: this.headers });
  }
  getCountryWisePopUptableData(uID:any,ucountry:any, uStatus:any){
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveCloseCountryJobsTrackerReport?userId=${uID}&country=${ucountry}&Status=${uStatus}`, { headers: this.headers })
  }


  GetActiveCloseCountryBrandJobsTrackerReport(uID:any,ucountry:any, uStatus:any,brand:any){
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveCloseCountryBrandJobsTrackerReport?userId=${uID}&country=${ucountry}&brand=${brand}&Status=${uStatus}`, { headers: this.headers })
  }

  GetActiveCloseCountryBrandJobsTrackerReportbysearchitems(search:any){
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveCloseCountryBrandJobsTrackerReportbysearchitems?searchItem=${search}`, { headers: this.headers })
  }

  GetActiveCloseBrandJobsTrackerReportbysearchitems(search:any){
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetActiveCloseBrandJobsTrackerReportbysearchitems?searchItem=${search}`, { headers: this.headers })
  }



  GetBrandCountryActiveCloseJobsReportExcel(userId:any,brand:any,country:any,status:any){
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetBrandCountryActiveCloseJobsReportPDF?userId=${userId}&brand=${brand}&country=${country}&Status=${status}`, { headers: this.headers })
  }
  GetLevelSubLevelByDepartmentId(deptId:any,levelId:any){
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetLevelSubLevelByDepartmentId?DeptId=${deptId}&LevelId=${levelId}`, { headers: this.headers })
  }

  ValidateAccessToken(token:any) {
    return this.http.get(`${environment.apiUrl}/api/User/ValidateAccessToken?token=${token}`, { headers: this.headers })
  }




  GetHiringStage() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStage`, { headers: this.headers })
  }


  GetBranchDisplayName(search:string) {
    return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetBranchDisplayName?SearchItem=${search}`, { headers: this.headers })
  }

  //


  AddOnboardingTaskdetails(obj:any) {
    return this.http.post(`${environment.apiUrl}/api/OnboardingTask/AddOnboardingTaskdetails`, obj, { headers: this.headers })
  }

  roleusernamesOnboarding(){
    return this.http.get(`${environment.apiUrl}/api/User/GetAllUserList`, { headers: this.headers });
}

GetOnboardingTaskdetails(objpage){
  return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetOnboardingTaskdetails?pageNumber=${objpage.pageNumber}&PagesizePerPage=${objpage.PagesizePerPage}`, { headers: this.headers });
}



GetOnboardingTaskdetailsbyId(onboardingTaskId:any,boolean,serarch) {
  return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetOnboardingTaskdetailsbyId?onboardingTaskId=${onboardingTaskId}&searchItem=${serarch}&notDefined=${boolean}`, { headers: this.headers })
}


UpdateEmailList(obj:any) {
  return this.http.post(`${environment.apiUrl}/api/OnboardingTask/UpdateEmailList?onboardingCampusListId=${obj.onboardingTaskId}&emailList=${obj.email}`, { headers: this.headers })
}


UpdatePublishStatus(obj:any) {
  return this.http.get(`${environment.apiUrl}/api/OnboardingTask/UpdatePublishStatus?onboardingTaskId=${obj.onboardingTaskId}&publishedStatus=${obj.publishedStatus}`, { headers: this.headers })
}

//

GetFlownametest(flowname:any){
  return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetFlownametest?flowName=${flowname}`, { headers: this.headers })
}



getUserDepartmentonboarging() {
  return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetAllDepartments`, { headers: this.headers });
}

// /api/OnboardingTask/GetOnboardingTaskdetailssearch?pageNumber=0&pageSizePerPage=10&searchItem=test



GetOnboardingTaskdetailssearch(objpagenew){
  return this.http.get(`${environment.apiUrl}/api/OnboardingTask/GetOnboardingTaskdetailssearch?pageNumber=${objpagenew.pageNumber}&PagesizePerPage=${objpagenew.PagesizePerPage}&searchItem=${objpagenew.searchItem}`, { headers: this.headers });
}

GetApprovalCategory() {
  return this.http.get(`${environment.apiUrl}/api/Candidate/GetApprovalCategory`, { headers: this.headers });
}
}





// return this.nationality = [
//   { id: 1, name: "India", flag: 'in flag' },
//   { id: 12, name: "Aland Islands", flag: 'ax flag' },
//   { id: 3, name: "Albania", flag: 'al flag' },
//   { id: 4, name: "Algeria", flag: 'dz flag' },
//   { id: 5, name: "American Samoa", flag: 'as flag' },
//   { id: 6, name: "United Arab Emirates", flag: 'ae flag' },
//   { id: 7, name: "United States", flag: 'us flag' },
//   { id: 8, name: "Uruguay", flag: 'uy flag' }
// ]
// return thi/api/Master/GetJobpostedbys.job = [
//   { id: 1, name: 'Andra pardesh' },
//   { id: 2, name: 'Karnataka' },
//   { id: 3, name: 'Telagana' },
//   { id: 4, name: 'Tamil nadu' }
// ]

// return this.grade = [
//   { id: 1, name: "Grade A" },
//   { id: 2, name: "Grade B" },
//   { id: 3, name: "Grade c" },
//   { id: 4, name: "Grade D" },
// ]

// return this.degree = [
//   { id: 1, name: "Be-tech" },
//   { id: 2, name: "Intermediate" },
//   { id: 3, name: "Albania" },
//   { id: 4, name: "Algeria" },
// ]

// return this.doc = [
//   { id: 1, name: 'Adhar' },
//   { id: 2, name: 'PAN' },
//   { id: 3, name: 'Driving licence' },
//   { id: 4, name: 'Ration card' }
// ]

// return this.department = [
//   { "id": 1, name: "Dept 1" },
//   { "id": 2, name: "Dept 2" },
//   { "id": 3, name: "Dept 3" },
//   { "id": 4, name: "Dept 4" }
// ]http://35.85.52.9/giis/api/Master/GetDepartment

// http://35.85.52.9/giis/api/Master/GetEducationList

// return this.level = [
//   { id: 1, name: "Level1" },
//   { id: 2, name: "Level2" },
//   { id: 3, name: "Level3" },
//   { id: 4, name: "Level4" },
// ]

// return this.resident = [
//   { id: 1, name: "Afghanistan" },
//   { id: 12, name: "land Islands" },
//   { id: 3, name: "Albania" },
//   { id: 4, name: "Algeria" },
// ]

// { id: 1, name: "Administrative" },
// { id: 2, name: "Teaching" },
// { id: 3, name: "Management" },
// { id: 4, name: "Sports" },
// { id: 5, name: "Security" },
